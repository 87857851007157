import {ListItemButton, ListItemText} from "@mui/material";
import {useAuth0} from "@auth0/auth0-react";

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <ListItemButton component="button" color="navbutton" onClick={() => logout({ returnTo: window.location.origin })}>
      <ListItemText primary="Log Out"/>
    </ListItemButton>
  );
}

export default LogoutButton;
