import React from 'react';
import {Routes, Route} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material";
import SearchPage from "./pages/SearchPage";
import MealPlanPage from "./pages/MealPlanPage";
import FavoritesPage from "./pages/FavoritesPage";
import MealPage from "./pages/MealPage";

function App() {
  const theme = createTheme({
    palette: {
      navbutton: {
        main: "#000"
      },
      navicon: {
        main: "#fff"
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route index element={<SearchPage/>} />
        <Route path="/meal/:id" element={<MealPage/>} />
        <Route path="/meals" element={<MealPlanPage/>} />
        <Route path="/favorites" element={<FavoritesPage/>} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
