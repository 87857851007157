import * as React from 'react';
import {forOwn} from 'lodash';
import FilterGroup from "./FilterGroup";

export default function Filters(props) {
  const { aggregations, activeFilters, handleFilterChange } = props;
  const filterGroups = [];

  forOwn(aggregations, (value, key) => {
    filterGroups.push(
      <FilterGroup key={key}
                   title={key}
                   aggregation={value}
                   activeFilters={activeFilters[key] || []}
                   handleFilterChange={handleFilterChange}
      />
    );
  });

  return (
    <>
      {filterGroups}
    </>
  )
}
