import NavBar from "../components/NavBar";
import {
  Box,
  Button,
  Container,
  Drawer,
  FormControl,
  Grid,
  InputLabel, List,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import MealList from "../components/MealList";
import {useEffect, useMemo, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import * as Realm from "realm-web";
import Ingredient from "../components/Ingredient";
import {find} from "lodash/collection";

const MealPlanPage = () => {
  const realmApp = Realm.getApp(process.env.REACT_APP_REALM_APP);
  const [mealIds, setMealIds] = useState([]);
  const [meals, setMeals] = useState([]);
  const [groceryListOpen, setGroceryListOpen] = useState(false);
  const [realmUser, setRealmUser] = useState(null);
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [commonYields, setCommonYields] = useState([]);
  const [selectedYield, setSelectedYield] = useState(null);


  const uniqueIngredients = useMemo(() => {
    const ingredientsById = {};
    meals.forEach((meal) => {
      const ingredients = meal.ingredients;
      ingredients.forEach(ingredient => {
        if (!ingredientsById[ingredient.id]) {
          ingredientsById[ingredient.id] = ingredient;
        }
      });
    });

    return Object.values(ingredientsById);
  }, [meals])

  const yieldsById = useMemo(() => {
    const result = {};

    meals.forEach((meal) => {
      const yields = find(meal.yields, { yields: selectedYield}) || meal.yields[0];
      yields.ingredients.forEach(ingredientYields => {
        if (result[ingredientYields.id]) {
          result[ingredientYields.id].amount += ingredientYields.amount;
        } else {
          result[ingredientYields.id] = {...ingredientYields}
        }
      });
    });

    return result;
  }, [selectedYield, meals]);

  const toggleGroceryList = () => {
    setGroceryListOpen(!groceryListOpen);
  }

  const handleYieldsChange = (event) => {
    setSelectedYield(event.target.value);
  }

  useEffect(() => {
    setCommonYields(meals[0]?.yields.map(yields => yields.yields) || []);
  }, [meals]);

  useEffect(() => {
    setSelectedYield(commonYields[0] || null);
  }, [commonYields])

  useEffect(() => {
    const getUserData = async () => {
      try {
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_REALM_APP,
          scope: "write:current_user",
        });

        if (!token) return;

        const credentials = Realm.Credentials.jwt(token);
        const loginRes = await realmApp.logIn(credentials);
        setRealmUser(loginRes);

        let dataRes = await loginRes.callFunction("getUserData");
        if (dataRes) {
          setMealIds(dataRes.meals || []);
          if (dataRes.mealData) {
            setMeals(dataRes.mealData);
          }
        }
      } catch (e) {
        console.log(e.message);
      }
    };

    if (isAuthenticated) {
      getUserData();
    }
  }, [getAccessTokenSilently, user?.id, isAuthenticated, realmUser, realmApp]);

  const handleMealChange = (event) => {
    const id = event.target.value;
    const index = mealIds.indexOf(id);
    const newMealIds = [...mealIds];
    if (index !== -1) {
      newMealIds.splice(index, 1);
    } else {
      newMealIds.push(id);
    }

    setMealIds(newMealIds);

    if (realmUser) {
      realmUser.callFunction("setMeals", { mealIds: newMealIds });
    }
  }

  const ingredientEls = uniqueIngredients.map(ingredient => (
    <Ingredient key={ingredient.id} ingredient={ingredient} yields={yieldsById[ingredient.id]}/>
  ));

  return (
    <>
      <NavBar>
        <Button onClick={toggleGroceryList} color="navicon">
          Grocery List
        </Button>
      </NavBar>
      <Drawer anchor="right" open={groceryListOpen} onClose={toggleGroceryList}>
        <Box sx={{width: 250, padding: '0 10px'}}>
          <Container maxWidth={"md"}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant={"h5"}>
                  Ingredients
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <InputLabel id={`common-yield-label`}>Yields</InputLabel>
                  <Select labelId={`common-yield-label`}
                          id={`common-yield-select`}
                          value={selectedYield}
                          onChange={handleYieldsChange} >
                    {commonYields.map(yields => (<MenuItem key={"gd-" + yields} value={yields}>{yields}</MenuItem>))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <List>
                  {ingredientEls}
                </List>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Drawer>
      <Grid container spacing={2}>
        <MealList meals={meals} handleMealChange={handleMealChange} mealIds={mealIds} />
      </Grid>
    </>
  );
}

export default MealPlanPage;
