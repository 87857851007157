import {Button, Card, CardContent, CardMedia, Checkbox, FormControlLabel, Grid, Link, Typography} from "@mui/material";
import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";

const MealList = (props) => {
  const {meals, handleMealChange, mealIds} = props;
  const {isAuthenticated, loginWithRedirect} = useAuth0();
  const navigate = useNavigate();

  const items = (meals || []).map((item) => {
    const cardContent = isAuthenticated ? (
      <FormControlLabel control={<Checkbox value={item.id}
                                           checked={mealIds.indexOf(item.id) !== -1}
                                           onChange={handleMealChange}
      />}
                        label={<Typography variant="body1">
                          {item.name}
                        </Typography>}
      />
    ) : (
      <Typography variant="body1">
        <Button onClick={() => loginWithRedirect()}>Login</Button>
        {item.name}
      </Typography>
    )
    return (
      <Grid item key={item.id} xs={12} md={4}>
        <Card>
          <Link href={`/meal/${item.id}`} onClick={(e) => {e.preventDefault(); navigate(`/meal/${item.id}`);}}>
            <CardMedia
              component="img"
              image={`${process.env.REACT_APP_IMG_BASE_URI}${item.imagePath}`}
            />
          </Link>
          <CardContent>
            {cardContent}
          </CardContent>
        </Card>
      </Grid>
    )
  });

  return (<>{items}</>);
}

export default MealList;
