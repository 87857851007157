import {ListItemButton, ListItemText} from "@mui/material";
import {useAuth0} from "@auth0/auth0-react";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <ListItemButton component="button" color="navbutton" onClick={() => loginWithRedirect()}>
      <ListItemText primary="Login"/>
    </ListItemButton>
  );
}

export default LoginButton;
