import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton, ListItemText,
  Toolbar
} from "@mui/material";
import {useAuth0} from "@auth0/auth0-react";
import LogoutButton from "./LogoutButton";
import LoginButton from "./LoginButton";
import MenuIcon from '@mui/icons-material/Menu';
import {useState} from "react";
import {useNavigate} from "react-router-dom";

const NavBar = (props) => {
  const {children} = props;

  const [ menuOpen, setMenuOpen ] = useState(false);

  const {isAuthenticated} = useAuth0();
  const authAction = isAuthenticated ? <LogoutButton/> : <LoginButton/>;

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const navigate = useNavigate();

  return (
    <Box sx={{flexGrow: 1}}>
      <AppBar position="static">
        <Toolbar sx={{pr: 0}}>
          <IconButton
            size="large"
            edge="start"
            color="navicon"
            aria-label="menu"
            sx={{mr: 1}}
            onClick={toggleMenu}
          >
            <MenuIcon/>
          </IconButton>
          {children}
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={menuOpen} onClose={toggleMenu}>
        <Box sx={{width: 250}}>
          <List>
            <ListItem disablePadding>
              <ListItemButton component="a" href="/" onClick={(e) => {e.preventDefault(); navigate('/');}}>
                <ListItemText primary="Search"/>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" href="/meals" onClick={(e) => {e.preventDefault(); navigate('/meals');}}>
                <ListItemText primary="Meal Plan"/>
              </ListItemButton>
            </ListItem>
            {/*<ListItem disablePadding>*/}
            {/*  <ListItemButton component="a" href="/favorites">*/}
            {/*    <ListItemText primary="Favorites"/>*/}
            {/*  </ListItemButton>*/}
            {/*</ListItem>*/}
            <ListItem disablePadding>
              {authAction}
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}

export default NavBar;
