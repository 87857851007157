import * as React from 'react';
import {Avatar, ListItem, ListItemAvatar, ListItemText} from "@mui/material";

export default function Ingredient(props) {
  const { ingredient, yields } = props;

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar src={`${process.env.REACT_APP_IMG_BASE_URI}${ingredient.imagePath}`} />
      </ListItemAvatar>
      <ListItemText primary={ingredient.name} secondary={`${yields.amount || ''} ${yields.unit || ''  }`} />
    </ListItem>
  )
}
