import * as Realm from "realm-web";
import {forOwn} from "lodash";

const PAGE_SIZE = parseInt(process.env.REACT_APP_PAGE_SIZE);

export const mongodb = {
  search: async function (term, filters, page = 0) {
    const realmApp = Realm.getApp(process.env.REACT_APP_REALM_APP);
    let user = realmApp.currentUser;
    if (!user) {
      user = await realmApp.logIn(Realm.Credentials.anonymous());
    }
    const mongo = user.mongoClient(process.env.REACT_APP_MONGO_SERVICE);
    const collection = mongo.db(process.env.REACT_APP_MONGO_DB).collection(process.env.REACT_APP_MONGO_COLLECTION);
    const pipeline = [];
    this._addSearchStage(pipeline, term);
    this._addFilterStage(pipeline, filters);
    this._addFacetStage(pipeline, (page - 1) * PAGE_SIZE);

    return collection.aggregate(pipeline);
  },

  getMeals: async function (mealIds) {
    const realmApp = Realm.getApp(process.env.REACT_APP_REALM_APP);
    const user = realmApp.currentUser;
    // const user = await realmApp.logIn(credentials);
    const mongo = user.mongoClient("mongodb-atlas");
    const collection = mongo.db("MealPlanner").collection("meals");
    return collection.find({id: { $in: mealIds } });
  },

  _addSearchStage: function (agg, term) {
    if (!term) return;
    agg.push({
      '$search': {
        'index': 'meals',
        'text': {
          'query': term,
          'path': {
            'wildcard': '*'
          }
        }
      }
    });
  },

  _addFilterStage: function (agg, filters) {
    const stageParts = {};
    let hasFilters = false;

    forOwn(filters, (value, key) => {
      if (!value.length) return;
      hasFilters = true;
      stageParts[`${key}.name`] = { $all: value };
    });

    if (!hasFilters) return;

    agg.push({
      '$match': stageParts
    });
  },

  _addFacetStage: function (agg, offset) {
    agg.push({
      '$facet': {
        'meals': [
          {'$skip': offset},
          {'$limit': PAGE_SIZE}
        ],
        'category': [
          {'$sortByCount': '$category.name'}
        ],
        'cuisines': [
          {'$unwind': '$cuisines'},
          {'$sortByCount': '$cuisines.name'}
        ],
        'ingredients': [
          {'$unwind': '$ingredients'},
          {'$sortByCount': '$ingredients.name'}
        ],
        'totalCount': [
          { '$count': 'count' }
        ]
      }
    });
  }
}
