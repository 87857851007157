import NavBar from "../components/NavBar";

const FavoritesPage = () => {

  return (
    <>
      <NavBar>
      </NavBar>
      Coming Soon
    </>
  );
}

export default FavoritesPage;
