import React from "react";
import {Card, CardContent, CardMedia, Grid, Typography} from "@mui/material";

export default function Steps(props) {
  const { steps } = props;

  console.log(steps);
  const stepEls = steps.map(step => (
    <Grid item md={6} key={step.index}>
      <Card>
        {step?.images[0]?.path ? (
          <CardMedia
            component="img"
            image={`${process.env.REACT_APP_IMG_BASE_URI}${step?.images[0]?.path}`}
          />
        ) : (<></>)}
        <CardContent>
          <Typography variant={"body1"}>
            {step.instructions}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  ));

  return (
    <Grid container spacing={2}>
      {stepEls}
    </Grid>
  );
}
