import * as React from 'react';
import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  List,
  MenuItem,
  Select,
  Toolbar,
  Typography
} from "@mui/material";
import {useMemo} from "react";
import {find} from "lodash/collection";
import Ingredient from "./Ingredient";
import Steps from "./Steps";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  mealImage: {
    maxWidth: '100%'
  }
});

export default function Meal({item}) {
  const [selectedYield, setSelectedYield] = React.useState(item?.yields[0]?.yields || 0);
  const classes = useStyles();

  const yieldsById = useMemo(() => {
    const result = {};
    const ingredientYields = find(item.yields, {yields: selectedYield})?.ingredients || [];
    ingredientYields.map(ingredientYield => result[ingredientYield.id] = {...ingredientYield});
    return result;
  }, [selectedYield, item.yields]);

  const handleYieldsChange = (event) => {
    setSelectedYield(event.target.value);
  }

  const ingredients = item.ingredients.map(ingredient => (
    <Ingredient key={`md-${ingredient.id}`} ingredient={ingredient} yields={yieldsById[ingredient.id]}/>
  ));

  return (
    <>
      <Container maxWidth={"xl"}>
        <Toolbar>
          <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
            {item.name}
          </Typography>
        </Toolbar>
      </Container>
      <Container maxWidth={"md"}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <img
              src={`${process.env.REACT_APP_IMG_BASE_URI}${item.imagePath}`}
              className={classes.mealImage}
              alt={item.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant={"body1"}>
              {item.description}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"h5"}>
              Ingredients
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <InputLabel id={`yield-${item._id}-label`}>Yields</InputLabel>
              <Select labelId={`yield-${item._id}-label`}
                      id={`yield-${item._id}-select`}
                      value={selectedYield}
                      onChange={handleYieldsChange}>
                {item.yields.map(yields => (
                  <MenuItem key={"md-" + yields} value={yields.yields}>{yields.yields}</MenuItem>))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <List>
              {ingredients.filter((_el, idx) => !(idx % 2))}
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <List>
              {ingredients.filter((_el, idx) => idx % 2)}
            </List>
          </Grid>
        </Grid>
        <Steps steps={item.steps}/>
      </Container>
    </>
  );
}
