import React, {useEffect} from 'react';
import { useParams } from "react-router-dom";
import { mealService } from "../services/mealService";
import Meal from "../components/Meal";

function MealPage() {
  const { id } = useParams();
  const [item, setItem] = React.useState(null);

  useEffect(() => {
    async function loadItem() {
      const res = await mealService.getMeals([id]);
      if (res) {
        setItem(res[0]);
      }
    }
    loadItem();
  }, [id]);

  return item ? (<Meal item={item} />) : (<></>);
}

export default MealPage;
