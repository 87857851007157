import NavBar from "../components/NavBar";
import SearchBox from "../components/SearchBox";
import {useLocation} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import {mealService} from "../services/mealService";
import {isEqual} from "lodash";
import {
  Box,
  Drawer,
  Grid,
  IconButton,
  Pagination
} from "@mui/material";
import {FilterList} from "@mui/icons-material";
import Filters from "../components/Filters";
import {useAuth0} from "@auth0/auth0-react";
import * as Realm from "realm-web";
import MealList from "../components/MealList";

function useQuery() {
  const {search} = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const decodeState = (string) => {
  try {
    return JSON.parse(string);
  } catch (_e) {
    return false;
  }
};

const realmApp = new Realm.App(process.env.REACT_APP_REALM_APP);

const SearchPage = () => {
  const query = useQuery();

  const [filterOpen, setFilterOpen] = useState(false);
  const [results, setResults] = useState({});
  const [activeFilters, setActiveFilters] = useState(decodeState(query.get('f')) || []);
  const [page, setPage] = useState(query.get('p') || 1);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState(query.get('q') || '');
  const [mealIds, setMealIds] = useState([]);
  const [realmUser, setRealmUser] = useState(null);
  const { user, getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();

  const toggleFilter = () => setFilterOpen(!filterOpen);

  useEffect(() => {
    mealService.search(search, activeFilters, page).then(res => {
      res = res[0];
      // TODO there a bug where zero results does not correctly reset page and page count
      if (!isEqual(res.meals, results.meals || [])) {
        setResults(res);
        if (!res.totalCount.length) {
          setPage(1);
          setPageCount(0);
        } else {
          setPageCount(Math.round(res.totalCount[0].count / process.env.REACT_APP_PAGE_SIZE));
        }
      }
    }).catch(err => {
      console.log(err);
    });
  }, [search, activeFilters, page, results.meals]);

  useEffect(() => {
    const getUserData = async () => {
      try {
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_REALM_APP,
          scope: "write:current_user",
        });

        if (!token) return;

        const credentials = Realm.Credentials.jwt(token);
        const loginRes = await realmApp.logIn(credentials);
        setRealmUser(loginRes);

        const dataRes = await loginRes.callFunction("getUserData");
        if (dataRes) {
          setMealIds(dataRes.meals || []);
        }
      } catch (e) {
        console.log(e.message);
      }
    };

    if (isAuthenticated && !isLoading) {
      getUserData();
    }
  }, [getAccessTokenSilently, user?.id, isAuthenticated, isLoading]);

  const handleMealChange = (event) => {
    const id = event.target.value;
    const index = mealIds.indexOf(id);
    const newMealIds = [...mealIds];
    if (index !== -1) {
      newMealIds.splice(index, 1);
    } else {
      newMealIds.push(id);
    }

    setMealIds(newMealIds);

    if (realmUser) {
      realmUser.callFunction("setMeals", { mealIds: newMealIds });
    }
  }

  const updateQueryString = () => {
    const newRelativePathQuery = window.location.pathname + (query.toString() ? `?${query.toString()}` : '');
    window.history.pushState(null, '', newRelativePathQuery);
  }

  const handleSearchChange = (event) => {
    const term = event.target.value;
    if (term !== search) {
      setSearch(term);
      if (term) {
        query.set('q', term);
      } else {
        query.delete('q');
      }

      updateQueryString();
    }
  };

  const hasFilters = function (filters) {
    const keys = Object.keys(filters);
    let result = false;
    keys.forEach((key) => {
      if (filters[key].length) {
        result = true;
      }
    });

    return result;
  };

  const handleFilterChange = (aggregation, newActiveFilters) => {
    const filters = {...activeFilters};
    filters[aggregation] = newActiveFilters
    setActiveFilters(filters);
    if (hasFilters(filters)) {
      query.set('f', JSON.stringify(filters));
    } else {
      query.delete('f');
    }

    updateQueryString();
  };

  const handlePageChange = (_event, pageNumber) => {
    setPage(pageNumber);
    if (pageNumber > 1) {
      query.set('p', pageNumber);
    } else {
      query.delete('p');
    }

    updateQueryString();
  }

  return (
    <>
      <NavBar>
        <SearchBox value={search} handleSearchChange={handleSearchChange}/>
        <IconButton
          size="large"
          edge="start"
          color="navicon"
          aria-label="menu"
          sx={{mr: 0}}
          onClick={toggleFilter}
        >
          <FilterList/>
        </IconButton>
      </NavBar>
      <Drawer anchor="right" open={filterOpen} onClose={toggleFilter}>
        <Box sx={{width: 250, padding: '0 10px'}}>
          <Filters
            aggregations={{
              ingredients: { title: 'Ingredients', buckets: results.ingredients || [] },
              category: { title: 'Category', buckets: results.category || [] },
              cuisines: { title: 'Cuisines', buckets: results.cuisines || [] },
            }}
            activeFilters={activeFilters}
            handleFilterChange={handleFilterChange}
          />
        </Box>
      </Drawer>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: 20}}>
          <Pagination count={pageCount} page={page} onChange={handlePageChange}/>
        </Grid>
        <MealList meals={results.meals} handleMealChange={handleMealChange} mealIds={mealIds} />
        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
          <Pagination count={pageCount} page={page} onChange={handlePageChange}/>
        </Grid>
      </Grid>
    </>
  );
}

export default SearchPage;
