import * as React from 'react';
import {Button, Checkbox, FormControlLabel, FormGroup, TextField} from "@mui/material";

export default function FilterGroup(props) {
  const { aggregation, title, activeFilters, handleFilterChange } = props;
  const [displayCount, setDisplayCount] = React.useState(5);
  const [search, setSearch] = React.useState();

  let filteredBuckets = React.useMemo(() => {
    if (search) {
      return aggregation.buckets.filter((bucket) => bucket._id.toLowerCase().indexOf(search) !== -1);
    }
    return aggregation.buckets
  }, [search, aggregation.buckets]);

  let bucketCount = filteredBuckets.length;

  let buckets = bucketCount > displayCount ? filteredBuckets.filter((bucket, idx) => idx < displayCount) : filteredBuckets;

  const handleChange = (event) => {
    const value = event.target.value;
    const newFilters = [...activeFilters];
    const index = newFilters.indexOf(value);
    if (index === -1) {
      newFilters.push(value);
    } else {
      newFilters.splice(index, 1);
    }
    handleFilterChange(title, newFilters);
  }

  const handleSearch = (event) => {
    setSearch(event.target.value.toLowerCase());
  }

  const bucketEls = buckets.map((bucket) => {
    if (!bucket._id) return null;
    const controlEl = <Checkbox value={bucket._id}
                                onChange={handleChange}
                                checked={activeFilters.indexOf(bucket._id) !== -1}/>
    return <FormControlLabel key={bucket._id}
                      control={controlEl}
                      label={`${bucket._id} (${bucket.count})`} />
  });

  return (
    <FormGroup>
      <TextField fullWidth
                 onChange={handleSearch}
                 id={`${title}-standard-basic`}
                 label={`${title[0].toUpperCase()}${title.substring(1)}`}
                 variant="standard"
      />
      {bucketEls}
      {bucketCount > displayCount ?
        <Button variant={'text'}
                onClick={() => setDisplayCount(displayCount + 5)}>
          Show More
        </Button> :
        <></>
      }
    </FormGroup>
  )
}
