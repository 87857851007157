import {mongodb} from "./adapter/mongodb";

export const mealService = {
  search: async function (term, filters, page = 0) {
    return mongodb.search(term, filters, page);
  },

  getMeals: async function (mealIds) {
    return mongodb.getMeals(mealIds);
  },
}
